import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken,onMessage  } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyD2UARhmrWKytRoXRWYrbZQADySVW_3Mqw",
    authDomain: "bam-kids.firebaseapp.com",
    projectId: "bam-kids",
    storageBucket: "bam-kids.appspot.com",
    messagingSenderId: "804421338095",
    appId: "1:804421338095:web:3793e73f1e9debf94b2c1a",
    measurementId: "G-4XJCE6SRZ7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
// Export the app function
export { app };

// Export the auth function
export const auth = getAuth(app);


export const getTokenData = () => {
  return getToken(messaging, { vapidKey: 'BHIXCZVmvrHF7vW_O7oRQKaR0IXqGqIM-W9xRiVzyFBMChpXTq7sutOrNQefaEEx7XCi1wTSQbIqWzbLoWKOsp4' }).then((currentToken:any) => {
    if (currentToken) {
      console.log('FCM Token:', currentToken);
      localStorage.setItem('fcmToken', currentToken);
    } else {
      console.log('No FCM registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.error('An error occurred while retrieving FCM token:', err);
  });
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
    	 console.log('@@@ Notification Payload =======', payload);
      resolve(payload);
    });
  });


